import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  datas: [],
  Indata: [],
  rentStatus: null,
  createStatus: null,
  editStatus: null,
  deleteStatus: null,
};

export const rentFetch = createAsyncThunk(
  "rent/rentFetch",
  async () => {
    try {
      const response = await axios.get(`${url}/rent`);
      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

export const fetchRentDataByYear = createAsyncThunk(
  "rent/RentDataByYear",
  async (values) => {
    try {
      const response = await axios.put(
        `${url}/rent/fetchData/${values.propertyId}`,
        values,
        setHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);
export const rentCreate = createAsyncThunk(
  "rent/rentCreate",
  async (values) => {
    try {
      const response = await axios.post(
        `${url}/rent`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

export const rentEdit = createAsyncThunk(
  "rent/rentEdit",
  async (values) => {
    try {
      const response = await axios.put(
        `${url}/rent/${values.rent._id}`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);
// add rent and filter by year
// By providing the Product(propertyId)
export const rentupdate = createAsyncThunk(
  "rent/rentEdit",
  async (values) => {
    try {
      const response = await axios.patch(
        `${url}/rent/update/${values.rent._id}`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

export const rentDelete = createAsyncThunk(
  "rent/rentDelete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${url}/rent/${id}`,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

const rentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {},
  extraReducers: {
    [rentFetch.pending]: (state, action) => {
      state.rentStatus = "pending";
    },
    [rentFetch.fulfilled]: (state, action) => {
      state.datas = action.payload;
      state.rentStatus = "success";
    },
    [rentFetch.rejected]: (state, action) => {
      state.rentStatus = "rejected";
    },
    [rentCreate.pending]: (state, action) => {
      state.createStatus = "pending";
    },
    [rentCreate.fulfilled]: (state, action) => {
      state.datas.push(action.payload);
      state.createStatus = "success";
      toast.success("rentStatus Created!", {
        position: "bottom-left",
      });
    },
    [rentCreate.rejected]: (state, action) => {
      state.createStatus = "rejected";
    },
    [rentDelete.pending]: (state, action) => {
      state.deleteStatus = "pending";
    },
    [rentDelete.fulfilled]: (state, action) => {
      const newList = state.datas.filter(
        (item) => item._id !== action.payload._id
      );
      state.datas = newList;
      state.deleteStatus = "success";
      toast.error("rent Deleted!", {
        position: "bottom-left",
      });
    },
    [rentDelete.rejected]: (state, action) => {
      state.deleteStatus = "rejected";
    },
    [rentEdit.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [rentEdit.fulfilled]: (state, action) => {
      const updaterent = state.datas.map((rent) =>
        rent._id === action.payload._id ? action.payload : rent
      );
      state.datas = updaterent;
      state.editStatus = "success";
      toast.info("rent Edited", {
        position: "bottom-left",
      });
    },
    [rentEdit.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },

    [rentupdate.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [rentupdate.fulfilled]: (state, action) => {
      const updaterent = state.datas.map((rents) =>
        rents._id === action.payload._id ? action.payload : rents
      );
      state.datas = updaterent;
      state.editStatus = "success";
      toast.info("rent Updated", {
        position: "bottom-left",
      });
    },
    [rentupdate.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },
    [fetchRentDataByYear.pending]: (state, action) => {
      state.rentStatus = "pending";
    },
    [fetchRentDataByYear.fulfilled]: (state, action) => {
      if (action.payload) {
        state.Indata = action.payload;
        state.rentStatus = "success";
      }
    },
    [fetchRentDataByYear.rejected]: (state, action) => {
      state.rentStatus = "rejected";
    },
  },
});

export default rentSlice.reducer;
