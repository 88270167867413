import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  datas: [],
  expenseStatus: null,
  createStatus: null,
  editStatus: null,
  deleteStatus: null,
};

export const expenseFetch = createAsyncThunk(
  "expense/expenseFetch",
  async () => {
    try {
      const response = await axios.get(`${url}/expense`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const expenseCreate = createAsyncThunk(
    "expense/expenseCreate",
  async (values) => {
    try {
      const response = await axios.post(
        `${url}/expense`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

export const expenseEdit = createAsyncThunk(
  "expense/expenseEdit",
  async (values) => {
    try {
      const response = await axios.put(
        `${url}/expense/${values.expense._id}`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);
// add expense and filter by year
// By providing the Product(propertyId)
export const expenseupdate = createAsyncThunk(
  "expense/expenseEdit",
  async (values) => {
    try {
      const response = await axios.patch(
        `${url}/expense/update/${values.expense._id}`,
        values,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

export const expenseDelete = createAsyncThunk(
  "expense/expenseDelete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${url}/expense/${id}`,
        setHeaders()
      );

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response?.data, {
        position: "bottom-left",
      });
    }
  }
);

const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {},
  extraReducers: {
    [expenseFetch.pending]: (state, action) => {
      state.expenseStatus = "pending";
    },
    [expenseFetch.fulfilled]: (state, action) => {
      state.datas = action.payload;
      state.expenseStatus = "success";
    },
    [expenseFetch.rejected]: (state, action) => {
      state.expenseStatus = "rejected";
    },
    [expenseCreate.pending]: (state, action) => {
      state.createStatus = "pending";
    },
    [expenseCreate.fulfilled]: (state, action) => {
      state.datas.push(action.payload);
      state.createStatus = "success";
      toast.success("Expense Created!", {
        position: "bottom-left",
      });
    },
    [expenseCreate.rejected]: (state, action) => {
      state.createStatus = "rejected";
    },
    [expenseDelete.pending]: (state, action) => {
      state.deleteStatus = "pending";
    },
    [expenseDelete.fulfilled]: (state, action) => {
      const newList = state.datas.filter(
        (item) => item._id !== action.payload._id
      );
      state.datas = newList;
      state.deleteStatus = "success";
      toast.error("Expense Deleted!", {
        position: "bottom-left",
      });
    },
    [expenseDelete.rejected]: (state, action) => {
      state.deleteStatus = "rejected";
    },
    [expenseEdit.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [expenseEdit.fulfilled]: (state, action) => {
      const updateExpense = state.datas.map((expense) =>
        expense._id === action.payload._id ? action.payload : expense
      );
      state.datas = updateExpense;
      state.editStatus = "success";
      toast.info("Expense Edited", {
        position: "bottom-left",
      });
    },
    [expenseEdit.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },

    [expenseupdate.pending]: (state, action) => {
      state.editStatus = "pending";
    },
    [expenseupdate.fulfilled]: (state, action) => {
      const updateExpense = state.datas.map((expense) =>
        expense._id === action.payload._id ? action.payload : expense
      );
      state.datas = updateExpense;
      state.editStatus = "success";
      toast.info("Expense Updated", {
        position: "bottom-left",
      });
    },
    [expenseupdate.rejected]: (state, action) => {
      state.editStatus = "rejected";
    },
  },
});

export default expenseSlice.reducer;
