import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../reducer/store";
import { loadBlockchain } from "../../slices/web3ContractSlice";
import axios from "axios";
import { url } from "../../slices/api";
import Property_ABI from '../../contract/property.json';
import { config } from '../../slices/config'
import { readContract } from '@wagmi/core'
import avat3 from "../../assets/images/avatar/avatar3.jpg";
// import avat3 from "../../assets/images/avatar/avatar";


const Details = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  const [loadchain, setloadchain] = useState();
  const [datas, setdatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isContractLoad, setisContractLoad] = useState(false);
  const [checkID, setcheckID] = useState();
  const dispatch = useAppDispatch();
  const { web3, contract, accounts, socketContract } = useAppSelector(
    (state) => state.web3Connect
  );
  const functionRead = async(addres) => {
    const result = await readContract(config, {
      abi: Property_ABI,
      address: addres,
      functionName: 'getCompletePropDetails',
    })
      return result;
      }

  useEffect(() => {
    // dispatch(loadBlockchain());
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${url}/products/find/${params.id}`);
        setProduct(res.data);
        !checkID && setcheckID(res.data.uid);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProduct();
    if (checkID) {
      // const contractofProperty = new web3.eth.Contract(
      //   Property_ABI,
      //   product.uid
      // );
      // !loadchain && setloadchain(contractofProperty);
      // if (loadchain) {
      //   const fetchData = async () => {
      //     try {
      //       let completeProp = await contractofProperty.methods
      //         .getCompletePropDetails()
      //         .call();
      //       setdatas(completeProp);
      //     } catch (err) {
      //       console.log(err);
      //     }
      //   };
      //   fetchData();
      // }
      const fetchda = async () => {
        await functionRead(checkID)
        .then((result) => {
          setdatas(result.PropertyDetails)
          // console.log("Property Details",result.PropertyDetails);
        })
        }
        fetchda()
    }
  }, [params.id, checkID]);

  return (
    <>
      <div className="dz-info m-b30">
        <div className="dz-meta">
          <ul>
            <li className="post-author">
              <Link to={"#"}>
                <img src={avat3} alt="" /> 
                <span>By Jone Doe</span>
              </Link>
            </li>
            <li className="post-date">
              <Link to={"#"}>
                {" "}
                {new Date(product.createdAt).toDateString()}
              </Link>
            </li>
            <li className="post-comment">
              <Link to={"#"}>3 comment</Link>
            </li>
          </ul>
        </div>
        {/* <h3 className="dz-title">{product.name}</h3> */}
        <div className="dz-post-text">
          {/* <p>{product.desc}</p> */}
          <p>
          Welcome to Bannbury Ln, a beautiful home nestled in a tranquil and vibrant neighborhood 
          in Alaska. This charming residence boasts 4 spacious bedrooms and 4 well-appointed 
          bathrooms, perfect for families or those seeking extra space. 
          The neighborhood itself is a true gem, featuring tree-lined streets, friendly neighbors, 
          and a sense of community that makes it feel like a retreat from the hustle and bustle of
          daily life.

          Currently, this exquisite home is not rented but has an expected rental value of 
          $2,500 per month, reflecting the desirability of the area. Imagine enjoying peaceful
          mornings surrounded by nature, with ample opportunities for outdoor activities just
          a stone's throw away. This is a place where you can truly appreciate the beauty of 
          your surroundings while enjoying the comforts of modern living.
          </p>
          <blockquote className="wp-block-quote">
            <p>
              “ A nice 4br 4bath rental in a nice neighborhood.   ”.
            </p>
            <cite> John Jackson </cite>
          </blockquote>
          <p>
          Along with the 4BR and 4BA, it has a two car garage, sewer and water, and is ready to rent.
          </p>
          <ul className="m-b30">
            <li>A fine rental for new investors.</li>
          </ul>
          <p>
          Welcome to Bannbury Ln, a beautiful home nestled in a tranquil and vibrant neighborhood 
          in Alaska. This charming residence boasts 4 spacious bedrooms and 4 well-appointed 
          bathrooms, perfect for families or those seeking extra space. 
          The neighborhood itself is a true gem, featuring tree-lined streets, friendly neighbors, 
          and a sense of community that makes it feel like a retreat from the hustle and bustle of
          daily life.
          </p>
        </div>
        <div className="dz-share-post">
          <div className="post-tags">
            <h6 className="m-b0 m-r10 d-inline">Tags:</h6>
            <Link to={"#"}>
              <span>Corporate</span>
            </Link>
            <Link to={"#"}>
              <span>Blog</span>
            </Link>
            <Link to={"#"}>
              <span>Marketing</span>
            </Link>
          </div>
          <div className="dz-social-icon dark">
            <ul>
              <li>
                <a
                  target="_blank"
                  className="fab fa-facebook-f"
                  href="https://www.facebook.com/"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="fab fa-instagram"
                  href="https://www.instagram.com/"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="fab fa-twitter"
                  href="https://twitter.com/"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="fab fa-youtube"
                  href="https://www.youtube.com/"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
